import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.profile" */ './RegisterDependent.vue'),
        meta: {
          label: 'dependent.title',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      secure: true,
      visible: false,
    },
    path: '/registerdependent',
  },
]);
