import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_SCREENING: async (context, params) => {
      let response;
      let json;
      let companyId;

      const user = context.rootGetters['credential/CURRENT_USER'];
      if (user) {
        companyId = user.signInUserSession.idToken.payload.family_name;
      }

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${companyId}/screening/type/${params.type}?subtype=${params.subtype}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_SCREENING', json);
    },
  },
  getters: {
    GET_SCREENING: (state) => {
      if (state.screening) {
        return JSON.parse(JSON.stringify(state.screening));
      }
    },
  },
  mutations: {
    POPULATE_SCREENING: (state, payload) => {
      if (payload) {
        state.screening = payload;
      }
    },
  },
  namespaced: true,
  state: {
    screening: undefined,
  },
};

CommonState.registerModule('screening', module);
