import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
    {
      children: [
        {
          component: () => import(/* webpackChunkName: "Common" */ './Schedule.vue'),
          meta: {
            label: 'schedule.my_availability',
          },
          path: '/schedule_availability/:id',
        },
      ],
      component: () => import(/* webpackChunkName: "Common" */ '../common/professional/Common.vue'),
      meta: {
        icon: 'calendar_today',
        label: 'schedule.my_availability',
        order: 6,
        subroutes: [
          {
            label: 'schedule.doctors',
            path: '/schedule_availability/:id',
            visible: true,
          },
        ],
        visible: false,
        visibleRoles: [],
      },
      path: '/schedule_availability/:id',
    },
  ]);
