import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.appointment" */ './Home.vue'),
        meta: {
          icon: 'login',
          label: 'ladingpage.home',
          visible: true,
        },
        name: 'home',
        path: '/',
      },
      {
        component: () => import(/* webpackChunkName: "app.appointment" */ './ListProfessionals.vue'),
        meta: {
          label: 'ladingpage.list_professionals',
        },
        name: 'professionals',
        path: '/professionals',
      },
      {
        component: () => import(/* webpackChunkName: "app.appointment" */ './Additional.vue'),
        meta: {
          label: 'ladingpage.additional',
        },
        name: 'additional',
        path: '/additional',
      },
    ],
    component: () => import(/* webpackChunkName: "app.appointment" */ './Common.vue'),
    meta: {
      icon: 'login',
      label: 'ladingpage.home',
      visible: false,
    },
    name: 'landing',
    path: '/',
  },
]);
