import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_CONTRACT_DISPLAY_POWERED_BY: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/DISPLAY_POWERED_BY`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_DISPLAY_POWERED_BY', json);
    },

    FETCH_USE_EMERGENCY_MODULE: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/USE_EMERGENCY_MODULE`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_USE_EMERGENCY_MODULE', json);
    },
  },
  getters: {
    DISPLAY_POWERED_BY: (state) => state.displayPoweredBy,
    USE_EMERGENCY_MODULE: (state) => state.useEmergencyModule,
  },
  mutations: {
    POPULATE_DISPLAY_POWERED_BY: (state, payload) => {
      if (payload) {
        state.displayPoweredBy = payload;
      }
    },
    POPULATE_USE_EMERGENCY_MODULE: (state, payload) => {
      if (payload) {
        state.useEmergencyModule = payload;
      }
    },
  },
  namespaced: true,
  state: {
    displayPoweredBy: null,
    useEmergencyModule: null,
  },
};

CommonState.registerModule('contract', module);
