import './assets/scss/main.scss';

import Vue from 'vue';
import vuetify from '@/vuetify';
import VueRouter from 'vue-router';
import VueTheMask from 'vue-the-mask';
import 'intersection-observer'; // safari 6+

import App from './App.vue';

// Componente
import PrimeIT, { CommonState, CommonRouter, Fetcher } from '@primeit/components-vue';
import { CommonSecurity } from '@dav/security-component-spa';

// Security
import '@/security';

// Pages
import '@/pages';

// States
import '@/state';

// Thirth-party integration
import '@/_integration';

CommonRouter.beforeResolve((to, from, next) => {
  try {
    const message = { event: 'sso_navigate', source: 'SSO', to: to.path };
    window.parent.postMessage(message, '*');
  } catch (error) {
    console.error(error);
  }

  next();
});

CommonSecurity.eventListener.add(CommonSecurity.events.EVENT_SIGN_IN, async (event) => {

  if (event.user && event.user.attributes.get('custom:roles') === 'P') {
    const usernameRaw = event.user.getAttribute('cognito:username');
    const username = usernameRaw.includes('@') ? usernameRaw.split('@')[1] : usernameRaw; 

    try {
      await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${username}/myprofile`, {
        body: JSON.stringify({
          company_id: event.user.attributes.get("family_name"),
          firebase_token: localStorage.getItem('token')
        }),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      });
    } catch (error) {
      console.error(error);
    }
  }

});

// Vue Setup
Vue.use(PrimeIT);
Vue.use(VueRouter);
Vue.use(VueTheMask);
// Vue.use(PluginDialogConfirm, { vuetify });

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  router: CommonRouter,
  store: CommonState,
  vuetify,
}).$mount('#app');
