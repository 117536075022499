import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.personschedule" */ './PersonSchedule.vue'),
        meta: {
          label: 'person.schedule',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      icon: 'schedule',
      label: 'person.schedule',
      order: 4,
      userRoles: ['P'],
      visible: true,
      visibleRules: ['allows_patient_to_schedule', 'access_person_schedule'],
    },
    path: '/personschedule',
  },
]);
