import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.profile" */ './Edit.vue'),
        meta: {
          label: 'patient.edit',
          secure: true,
        },
        path: 'edit/:id',
      },
      {
        component: () => import(/* webpackChunkName: "app.profile" */ './EHR.vue'),
        meta: {
          label: 'patient.ehr',
          secure: true,
        },
        path: 'ehr/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      secure: true,
      visible: false,
    },
    path: '/profile/person',
  },
]);
