const professionalRoutes = [
  {
    children: [
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.professional" */ './Checkin.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        name: 'PROFESSIONAL_CHECKIN',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.professional" */ './Dashboard.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        name: 'PROFESSIONAL_DASHBOARD',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.professional" */ './ReadyToAttendance.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        name: 'PROFESSIONAL_READY_TO_ATTENDANCE',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.professional" */ './AttendanceRoom.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        name: 'PROFESSIONAL_ATTENDANCE_ROOM',
        path: '',
      },
			{
				children: [],
				component: () => import(/* webpackChunkName: "app.emergency.professional" */ './PostAttendance.vue'),
				meta: {
				label: 'emergency.post_attendance',
				},
				name: 'PROFESSIONAL_POST_ATTENDANCE',
				path: '',
			},
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.professional" */ './EndRoom.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        name: 'PROFESSIONAL_END_ROOM',
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.emergency.professional" */ './Common.vue'),
    meta: {
      label: 'emergency.attendance_now',
    },
    name: 'professional',
    path: '',
  },
];

export default professionalRoutes;
