import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params.id}/schedule`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  namespaced: true,
};

CommonState.registerModule('schedule', module);
