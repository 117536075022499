import { CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    RESET_FEEDBACK(context, params) {
      return context.commit('POPULATE_FEEDBACK', {});
    },
  },
  getters: {
    FEEDBACK: (state) => state.feedback,
  },
  mutations: {
    POPULATE_FEEDBACK: (state, payload) => {
      if (payload) {
        state.feedback = payload;
      }
    },
  },
  namespaced: true,
  state: {
    feedback: null,
  },
};

CommonState.registerModule('common', module);
