import { CommonRouter, CommonState } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    beforeEnter: (_to, _from, next) => {
      const user = CommonState.getters['credential/CURRENT_USER'];
      const isProfessionalUser = user?.signInUserSession?.idToken?.payload['custom:roles'] !== 'P';

      if (isProfessionalUser) {
        return CommonRouter.push({ name: 'professionalHome' });
      }

      next();
    },
    children: [
      {
        component: () => import(/* webpackChunkName: "app.home" */ './HomePatient.vue'),
        meta: {
          front: 'consultorio',
          label: 'home',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      front: 'consultorio',
      icon: 'home',
      label: 'home',
      order: 0,
      visible: false,
    },
    path: '/',
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.home" */ './Home.vue'),
        meta: {
          front: 'consultorio',
          label: 'home',
          secure: true,
        },
        name: 'professionalHome',
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      front: 'consultorio',
      icon: 'home',
      label: 'home',
      order: 0,
      visible: false,
    },
    path: '/',
  },
]);
