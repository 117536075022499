import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    component: () => import(/* webpackChunkName: "security.common" */ './Kioski.vue'),
    meta: {
      visible: false,
    },
    name: 'kioski-mode',
    path: '/kioski',
    props: true,
  },
]);
