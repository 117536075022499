import { CommonState, Fetcher } from '@primeit/components-vue';
import moment from 'moment';

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      let response;
      let json;

      const today = moment().endOf('day').valueOf();
      const subject = `person|${params.patientId}`;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_SERVICE_REQUEST}?to=${today}&subject=${subject}&category=3457005&status=active&code=103696004`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ALL', json);
    },

    FETCH_DELETE: async (_context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_SERVICE_REQUEST}/${params.id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      return json;
    },

    FETCH_UPDATE: async (_context, params) => {
      let response;
      let json;
      const id = params.id;
      delete params.id;
      delete params.company_id;
      delete params.requesterName;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_SERVICE_REQUEST}/${id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      return json;
    },
  },
  getters: {
    GET_ALL: (state) => {
      return JSON.parse(JSON.stringify(state.serviceRequests));
    },
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.serviceRequests = payload;
      }
    },
  },
  namespaced: true,
  state: {
    serviceRequests: [],
  },
  strict: true,
};


CommonState.registerModule('serviceRequest', module);
