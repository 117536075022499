import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.professional" */ './Edit.vue'),
        meta: {
          label: 'doctor.edit',
          secure: true,
        },
        path: 'edit/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      secure: true,
      visible: false,
    },
    path: '/profile/professional',
  },
]);
