import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.requestreturnappointment" */ './RequestReturnAppointment.vue'),
        meta: {
          label: 'requestReturn.title',
          secure: true,
        },
        path: 'requestreturnappointment/:id'
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      label: 'requestReturn.title',
    },
    path: '/appointment'
  },
]);
