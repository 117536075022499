import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    component: () => import(/* webpackChunkName: "security.common" */ './Totem.vue'),
    meta: {
      visible: false,
    },
    path: '/t',
  },
]);
