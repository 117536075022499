import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    component: () => import(/* webpackChunkName: "app.pagenotfound" */ './pageNotFound.vue'),
    meta: {
      front: 'consultorio',
      secure: true,
      visible: false,
    },
    path: '/pagenotfound',
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.pagenotfound" */ './pageNotFound.vue'),
        meta: {
          front: 'consultorio',
          label: 'pageNotFound',
          secure: true,
          visible: false,
        },
        path: '*',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      icon: 'dns',
      label: 'domains.Edit',
      order: 600,
      visible: false,
    },
    path: '*',
  },
]);
