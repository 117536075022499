import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "security.common" */ './Benefitclub'),
        meta: {
          label: 'benefit_club',
        },
        path: '',
      }
    ],
    component: () => import(/* webpackChunkName: "security.common" */ '../common/person/Common'),
    meta: {
      label: 'benefit_club',
      visible: false,
    },
    name: 'benefit-club',
    path: '/benefitclub',
  },
]);
