import { Config } from '@dav/security-component-spa';
import { API, CommonState, Fetcher } from '@primeit/components-vue';
import moment from 'moment';

// const mock = {
//   finance: {}
// };

const module = {
  actions: {
    FETCH_BENEFITCLUB_PSO: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_FINANCIAL}/benefitclub/pso`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_BENEFITCLUB_PSO', json);
    },
    FETCH_COMPANY_FINANCE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/financial`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_FINANCE', json);
    },
    FETCH_COMPANY_FINANCERESUME: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/financialresume`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_FINANCERESUME', json);
    },
    FETCH_INSTALLMENTS: async (context, financial) => {
      const apiName = 'FinancialAPI';
      const path = '/installments';

      const config = await Config.getInstance();

      const myInit = {
        body: financial,
        headers: {
          'x-company-id': config.get('dav-company-id'),
          'x-utc-offset': moment().utcOffset().toString(),
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        });

      context.commit('POPULATE_INSTALLMENTS', json);
    },
    FETCH_SAVE: async (context, financial) => {
      let response;
      let json;
      const companyId = financial.companyId;
      delete financial.companyId;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${companyId}/financial`, {
          body: JSON.stringify(financial),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_BENEFITCLUB_PSO: (state) => state.benefitclubPso,
    GET_COMPANY_FINANCE: (state) => state.entityFinance,
    GET_COMPANY_FINANCERESUME: (state) => state.entityFinanceResume,
    GET_INSTALLMENTS: (state) => state.installments
  },
  mutations: {
    POPULATE_BENEFITCLUB_PSO: (state, payload) => {
      if (payload) {
        state.benefitclubPso = payload;
      }
    },
    POPULATE_COMPANY_FINANCE: (state, payload) => {
      if (payload) {
        state.entityFinance = payload;
      }
    },
    POPULATE_COMPANY_FINANCERESUME: (state, payload) => {
      if (payload) {
        state.entityFinanceResume = payload;
      }
    },
    POPULATE_INSTALLMENTS: (state, payload) => {
      if (payload) {
        state.installments = payload;
      }
    }
  },
  namespaced: true,
  state: {
    benefitclubPso: null,
    entityFinance: null,
    entityFinanceResume: null,
    installments: null,
  },
};

CommonState.registerModule('financial', module);
