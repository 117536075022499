import { CommonState } from '@primeit/components-vue';

const readAndCommit = async (context, key, url, init = undefined) => {
  const response = await fetch(url, init);
  try {
    const json = await response.json();
    if (response.status === 200) {
      context.commit(key, json);
    } else {
      context.commit('failure', json);
    }
  } catch (e) {
    context.commit('failure', {
      code: response.status,
      message: e.message,
    });
  }
};

const module = {
  actions: {
    check: async (context, params) => {
      const response = await fetch('/', { method: 'HEAD' });
      await readAndCommit(
        context,
        'checked',
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CREDENTIAL}/eso/${params.id}/check`,
        {
          headers: {
            'x-domain-dav-company-id': response.headers.get('x-domain-dav-company-id'),
          },
        }
      );
    },
  },
  getters: {
    check: (state) => state.check,
    fail: (state) => state.fail,
  },
  mutations: {
    checked: (state, payload) => (state.check = payload),
    failed: (state, payload) => (state.fail = payload),
  },
  namespaced: true,
  state: {
    check: undefined,
    fail: undefined,
  },
};

export const state = {
  register() {
    CommonState.registerModule('eso', module);
  },
  unregister() {
    CommonState.unregisterModule('eso');
  },
};
