import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.choicedependent" */ './ChoosenDependent.vue'),
        meta: {
          label: 'choiceDependent.title',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      secure: true,
      visible: false,
    },
    path: '/choosendependent',
  },
]);
