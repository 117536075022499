import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    alias: '/a/:id',
    children: [
      {
        component: () => import(/* webpackChunkName: "app.doctoroffice" */ './AccessRoom.vue'),
        meta: {
          label: 'doctoroffice.room',
          secure: false,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.doctoroffice" */ '../common/professional/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/accessroom/:id',
  },

  {
    alias: '/cn/:id',
    children: [
      {
        component: () => import(/* webpackChunkName: "app.doctoroffice" */ './Cancel.vue'),
        meta: {
          label: 'doctoroffice.room',
          secure: false,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.doctoroffice" */ '../common/professional/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/cancel/:id',
  },

  {
    alias: '/p/:id',
    children: [
      {
        component: () => import(/* webpackChunkName: "app.doctoroffice" */ './Payment.vue'),
        meta: {
          label: 'doctoroffice.payment',
          secure: false,
        },
        path: '',
        props: true,
      },
    ],
    component: () => import(/* webpackChunkName: "app.doctoroffice" */ '../common/person/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/payment/:id',
    props: true,
  },

  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.doctoroffice" */ './EHR.vue'),
        meta: {
          label: 'doctoroffice.ehr',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.doctoroffice" */ '../common/professional/Common.vue'),
    meta: {
      icon: 'mdi-format-list-bulleted',
      label: 'doctoroffice.ehr',
      order: 8,
      secure: true,
      userRoles: ['DC', 'D'],
      visible: true,
      visibleRules: ['allow_professional_view_patient_history'],
    },
    path: '/professional/ehr',
  },
]);
