import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_COMPANY_FINANCERESUME: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/financialresume`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_FINANCERESUME', json);
    },
    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/settings`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
  },
  getters: {
    GET_FINANCERESUME: (state) => {
      return JSON.parse(JSON.stringify(state.financeresume));
    },
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
  },
  mutations: {
    POPULATE_COMPANY_FINANCERESUME: (state, payload) => {
      if (payload) {
        state.financeresume = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
  },
  namespaced: true,
  state: {
    entity: null,
    financeresume: null,
  },
  strict: true,
};

CommonState.registerModule('settings', module);
