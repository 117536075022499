/**
 * Módulo comum de domínio do sistema
 * Author: Luciano Jesus Lima <lucianojl@primeit.com.br>
 */
import { CommonState, Fetcher } from '@primeit/components-vue';

const ordemAlfabeticaValue = (a, b) => {
  if (a.value.toLowerCase() > b.value.toLowerCase()) {
    return 1;
  }
  if (a.value.toLowerCase() < b.value.toLowerCase()) {
    return -1;
  }
  // a must be equal to b
  return 0;
};

const module = {
  actions: {
    /**
     * Busca endereço a partir do CEP
     *
     *  Exemplo de envio
     *  const params = { cep: 8888888 }
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_ADDRESS: async (context, params) => {
      let json;
      try {
        const response = await fetch(`${process.env.VUE_APP_CEP_API}/${params.cep}/json`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          empty: {},
          error: {
            code: 500,
            message: ['Impossível acessar o servidor. Tente novamente'],
          },
        };
      }
      context.commit('POPULATE_ADDRESS', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_APPOINTMENT_SOURCE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/APPOINTMENT_SOURCE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_APPOINTMENT_SOURCE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_BILL_TYPE_OPTIONS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/BILL_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_BILL_TYPE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_CITIES: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/CITY?parent=${params.state}`
        );
        if (response.ok) {
          json = await response.json();
          const citys = [];
          json.values.sort(ordemAlfabeticaValue);
          for (const city of json.values) {
            citys.push({ id: parseInt(city.code), nome: city.value });
          }
          json = citys;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CITIES', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_COUNTRY: async (context, params) => {
      let json;
      try {
        // const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/COUNTRY_LIST`);
        const response = await fetch(`${process.env.VUE_APP_COMMON_DOMAINS_SERVER_BASE}/COUNTRY_LIST.json`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COUNTRY', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_GENDER_OPTIONS: async (context, params) => {
      context.commit('POPULATE_GENDER', []);
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/GENDER`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_GENDER', json);
    },

    FETCH_PARTICIPANT_ROLE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PARTICIPANT_ROLE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PARTICIPANT_ROLE', json);
    },

    FETCH_PROFESSIONAL_REGISTER_TYPE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PROFESSIONAL_REGISTER_TYPE`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PROFESSIONAL_REGISTER_TYPE', json);
    },

    FETCH_REQUEST_SPECIALTYS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/REQUEST_SPECIALTYS`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_REQUEST_SPECIALTYS', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_STATES: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/REGION`);
        if (response.ok) {
          json = await response.json();
          const regions = [];
          json.values.sort(ordemAlfabeticaValue);
          for (const region of json.values) {
            regions.push({ id: region.code, sigla: region.code });
          }

          json = regions;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATES', json);
    },

    /**
     * Busca lista de cidades
     *
     *  Exemplo de envio
     *  const params = { state: <codigo ID do estado, não a sigla> }
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_STATUS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/STATUS`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATUS', json);
    },

    /**
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_STATUS_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/STATUS_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATUS_APPOINTMENT', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_TIME_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TIME_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TIME_APPOINTMENT', json);
    },

    /**
     * Busca lista de Estados
     * OBS.: Guardar na base a sigla, não o id. O id serve para a chamada de cidades
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_TYPE_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TYPE_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TYPE_APPOINTMENT', json);
    },

    FETCH_TYPE_PAYMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PAYMENT_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PAYMENT_TYPE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_TYPE_USER: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TYPE_USER`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TYPE_USER', json);
    },
  },
  getters: {
    APPOINTMENT_SOURCE: (state) => state.appointmentSource,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    BILL_TYPE_OPTIONS: (state) => state.billTypeOptions,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    GENDER_OPTIONS: (state) => state.genderOptions,

    GET_ADDRESS: (state) => state.address,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    GET_CITIES: (state) => state.cities,
    GET_COUNTRY: (state) => state.country,

    GET_PAYMENT_TYPE: (state) => state.paymentTypes,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    GET_REQUEST_SPECIALTYS: (state) => state.requestSpecialtys,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    GET_STATES: (state) => state.states,

    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    GET_TYPE_USER: (state) => state.typeUserOptions,
    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    PARTICIPANT_ROLE: (state) => state.participantRole,
    PROFESSIONAL_REGISTER_TYPES: (state) => state.professional_register_types,

    STATUS_APPOINTMENT: (state) => state.statusAppointment,

    /**
     * Exemplo de Retorno
     *     [
     *     {
     *          "id": 3500105,
     *          "nome": "Adamantina",
     *          "microrregiao": {
     *            "id": 35035,
     *            "nome": "Adamantina",
     *            "mesorregiao": {
     *              "id": 3508,
     *              "nome": "Presidente Prudente",
     *              "UF": {
     *                "id": 35,
     *                "sigla": "SP",
     *                "nome": "São Paulo",
     *                "regiao": {
     *                  "id": 3,
     *                  "sigla": "SE",
     *                  "nome": "Sudeste"
     *                }
     *              }
     *           }
     *          }
     *        }
     *     ]
     * @returns {*}
     * @constructor
     */
    TIME_APPOINTMENT: (state) => state.timeAppointment,
    /**
     * Exemplo de Retorno
     *
     *    [
     *        {
     *         "id": 11,
     *          "sigla": "RO",
     *          "nome": "Rondônia",
     *          "regiao": {
     *            "id": 1,
     *            "sigla": "N",
     *            "nome": "Norte"
     *          }
     *        }
     *    ]
     *
    /**
     *
     * @param state
     * @returns {*}
     * @constructor
     */
    TYPE_APPOINTMENT: (state) => state.typeAppointment,
    TYPE_GET: (state) => state.typeUserOptions,
  },
  mutations: {
    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_ADDRESS: (state, payload) => {
      if (payload) {
        state.address = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_APPOINTMENT_SOURCE: (state, payload) => {
      if (payload) {
        state.appointmentSource = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_BILL_TYPE: (state, payload) => {
      if (payload) {
        state.billTypeOptions = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_CITIES: (state, payload) => {
      if (payload) {
        state.cities = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_COUNTRY: (state, payload) => {
      if (payload) {
        state.country = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_GENDER: (state, payload) => {
      if (payload) {
        state.genderOptions = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_PARTICIPANT_ROLE: (state, payload) => {
      if (payload) {
        state.participantRole = payload;
      }
    },

    POPULATE_PAYMENT_TYPE: (state, payload) => {
      if (payload) {
        state.paymentTypes = payload;
      }
    },

    POPULATE_PROFESSIONAL_REGISTER_TYPE: (state, payload) => {
      if (payload) {
        state.professional_register_types = payload;
      }
    },

    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_REQUEST_SPECIALTYS: (state, payload) => {
      if (payload) {
        state.requestSpecialtys = payload;
      }
    },
    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_STATES: (state, payload) => {
      if (payload) {
        state.states = payload;
      }
    },

    POPULATE_STATUS: (state, payload) => {
      if (payload) {
        state.statusOptions = payload;
      }
    },
    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_STATUS_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.statusAppointment = payload;
      }
    },

    POPULATE_TIME_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.timeAppointment = payload;
      }
    },
    /**
     *
     * @param state
     * @param payload
     * @constructor
     */
    POPULATE_TYPE_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.typeAppointment = payload;
      }
    },
    POPULATE_TYPE_USER: (state, payload) => {
      if (payload) {
        state.typeUserOptions = payload;
      }
    },
  },
  namespaced: true,
  state: {
    address: null,
    appointmentSource: [],
    billTypeOptions: [],
    cities: [],
    country: [],
    genderOptions: [],
    participantRole: [],
    paymentTypes: [],
    professional_register_types: [],
    requestSpecialtys: [],
    states: [],
    statusAppointment: [],
    statusOptions: [],
    timeAppointment: [],
    typeAppointment: [],
    typeUserOptions: [],
  },
};

CommonState.registerModule('domain', module);
