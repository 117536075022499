import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.appointment" */ './Detail.vue'),
        meta: {
          label: 'appointment.detail',
          secure: true,
        },
        path: 'detail/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      icon: 'list_alt',
      label: 'appointment.list_all',
      order: 200,
      visible: false,
    },
    path: '/appointment',
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.professional" */ './ListProfessionalScheduled.vue'),
        meta: {
          label: 'appointment.list_age',
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      icon: 'today',
      label: 'appointment.list_today',
      order: 2,
      userRoles: ['DC', 'D'],
      visible: true,
    },
    path: '/professional/listscheduled',
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "app.professional" */ './ListProfessionalAppointment.vue'),
        meta: {
          label: 'appointment.list_age',
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      icon: 'list_alt',
      label: 'appointment.list_all',
      order: 3,
      secure: true,
      userRoles: ['DC', 'D'],
      visible: true,
    },
    path: '/professional/listappointment',
  },
]);
