import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL_CUSTOM_MESSAGES: async (context, params) => {
      if (!params) {
        params = {};
      }
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/custommessage`,
        params,
        true
      );
      context.commit('POPULATE_ALL_CUSTOM_MESSAGES', response);
    },
    FETCH_SETTINGS: async (context, params) => {
      let response, json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params}/settings`, {
          method: 'GET',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
        });

        json = await response.json();
      }
      catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_SETTINGS', json);
    }
  },
  getters: {
    GET_ALL_CUSTOM_MESSAGES: (state) => {
      return JSON.parse(JSON.stringify(state.customMessages));
    },
    GET_SETTINGS: (state) => {
      return state.settings;
    }
  },
  mutations: {
    POPULATE_ALL_CUSTOM_MESSAGES: (state, payload) => {
      if (payload) {
        state.customMessages = payload;
      }
    },
    POPULATE_SETTINGS: (state, payload) => {
      if (payload) {
        state.settings = payload;
      }
    }
  },
  namespaced: true,
  state: {
    customMessages: null,
    settings: null
  },
  strict: true,
};

CommonState.registerModule('company', module);
