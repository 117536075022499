const personRoutes = [
  {
    children: [
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './PersonArrival'),
        meta: {
          label: 'emergency.arrivalTitle',
          step: 1,
        },
        name: 'PERSON_ARRIVAL',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './CheckResources'),
        meta: {
          label: 'emergency.flow_steps.resources',
          step: 2,
        },
        name: 'PERSON_CHECK_RESOURCES',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './PerformPayment.vue'),
        meta: {
          label: 'emergency.flow_steps.payment',
          step: 3,
        },
        name: 'PERSON_PERFORM_PAYMENT',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './ReportComplaint'),
        meta: {
          label: 'emergency.flow_steps.reason',
          step: 4,
        },
        name: 'PERSON_REPORT_COMPLAINT',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './WaitingRoom'),
        meta: {
          label: 'emergency.flow_steps.rank',
          step: 5,
        },
        name: 'PERSON_WAITING_ROOM',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './ReadyToAttendance'),
        meta: {
          label: 'emergency.attendance_now',
          step: 6,
        },
        name: 'PERSON_READY_TO_ATTENDANCE',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './AttendanceRoom.vue'),
        meta: {
          label: 'emergency.attendance_now',
          step: 7,
        },
        name: 'PERSON_ATTENDANCE_ROOM',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './PostAttendance.vue'),
        meta: {
          label: 'emergency.post_attendance',
          step: 8,
        },
        name: 'PERSON_POST_ATTENDANCE',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './FillNps/index.vue'),
        meta: {
          label: 'emergency.fill_nps',
          step: 9,
        },
        name: 'PERSON_FILL_NPS',
        path: '',
      },
      {
        children: [],
        component: () => import(/* webpackChunkName: "app.emergency.person" */ './EndRoom.vue'),
        meta: {
          label: 'emergency.attendance_now',
          step: 10,
        },
        name: 'PERSON_END_ROOM',
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.emergency.person" */ './Common.vue'),
    meta: {
      label: 'emergency.attendance_now',
    },
    name: 'person',
    path: '',
  },
];

export default personRoutes;
