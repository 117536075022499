import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
import { secureFetch } from '@dav/security-component-spa';
const fetchHelper = new FetchHelper();

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL, params);
      context.commit('POPULATE_ALL', responseJson);
    },
    FETCH_AVAILABILITY_CALENDAR: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/availabilitycalendar`,
        params
      );
      context.commit('POPULATE_SCHEDULES', responseJson);
    },
    FETCH_COMBO: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/combo`,
        params
      );
      context.commit('POPULATE_COMBO', responseJson);
    },
    FETCH_EDIT_AVAILABILITY: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT_AVAILABILITY', json);
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}/myprofile`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },

    FETCH_LANDING_PERMALINK: async (context, params) => {
      let response;
      let json;

      try {
        response = await secureFetch(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/landing/permalink`, {
          headers: {
            'x-company-id': params,
          },
        });
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = null;
      }

      context.commit('POPULATE_PERMALINK', json);
    },
    FETCH_NEXT_DAY_AVAILABLE: async (context, params) => {
      let response;
      let json;

      try {
        response = await secureFetch(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/landing/${params.id}/schedule/nextdayavailable`,
          {
            headers: {
              'x-company-id': params.companyId,
            },
          }
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      return json;
    },
    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params.id}?fields=${params.fields}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
    FETCH_PROFILE: async (context, params) => {
      let response;
      let json;

      try {
        response = await secureFetch(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/landing/${params.id}/profile`,
          {
            headers: {
              'x-company-id': params.companyId,
            },
          }
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      return json;
    },
    FETCH_SCHEDULE_PROFESSIONAL: async (context, params) => {
      let response;
      let json;

      const filter = `month_available=${params.monthAvailable}`;

      try {
        response = await secureFetch(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/landing/${
            params.id
          }/schedule?filter=${encodeURIComponent(filter)}`,
          {
            headers: {
              'x-company-id': params.companyId,
            },
          }
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      return json;
    },
    FETCH_SPECIALTIES: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/specialty`,
        params
      );
      context.commit('POPULATE_SPECIALTIES', responseJson);
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params.id}/myprofile`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_COMBO: (state) => state.combo,
    GET_DAY_AVAIABLE: (state) => {
      return JSON.parse(JSON.stringify(state.dayAvailable));
    },
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    // GET_LANDING_SCHEDULES: (state) => {
    //   return JSON.parse(JSON.stringify(state.landingSchedules));
    // },
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_PERMALINK: (state) => {
      return JSON.parse(JSON.stringify(state.permalink));
    },
    // GET_PROFILE: (state) => {
    //   return JSON.parse(JSON.stringify(state.profile));
    // },
    GET_SCHEDULES: (state) => {
      return JSON.parse(JSON.stringify(state.schedules));
    },
    GET_SPECIALTIES: (state) => state.specialties,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_COMBO: (state, payload) => {
      if (payload) {
        state.combo = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_EDIT_AVAILABILITY: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    // POPULATE_LANDING_SCHEDULES: (state, payload) => {
    //   if (payload) {
    //     state.landingSchedules = payload;
    //   }
    // },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_PERMALINK: (state, payload) => {
      if (payload) {
        state.permalink = payload;
      }
    },
    // POPULATE_PROFILE: (state, payload) => {
    //   if (payload) {
    //     state.profile = payload;
    //   }
    // },
    POPULATE_SCHEDULES: (state, payload) => {
      if (payload) {
        state.schedules = payload;
      }
    },
    POPULATE_SPECIALTIES: (state, payload) => {
      if (payload) {
        state.specialties = payload;
      }
    },
  },
  namespaced: true,
  state: {
    combo: null,
    dayAvailable: [],
    edit: null,
    entities: [],
    entity: null,
    landingSchedules: [],
    permalink: [],
    schedules: [],
    specialties: null,
  },
};

CommonState.registerModule('doctor', module);
