import { CommonState, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();

const module = {
  actions: {
    FETCH_SPECIALTIES: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/specialty`,
        params
      );
      context.commit('POPULATE_SPECIALTIES', responseJson);
    },
  },
  getters: {
    GET_SPECIALTIES: (state) => state.specialties,
  },
  mutations: {
    POPULATE_SPECIALTIES: (state, payload) => {
      if (payload) {
        state.specialties = payload;
      }
    },
  },
  namespaced: true,
  state: {
    specialties: null,
  },
};

CommonState.registerModule('specialty', module);
